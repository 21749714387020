

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { intervalTime, URL } from '../utils/constants'
import InactiveMachineTable from './InactiveMachineTable';
import { useUserContext } from '../context/user_context';
import AuthUser from './AuthUser';

const InactiveMachine = () => {

    const [machines, setMachines] = useState([]);
    const [loading, setLoading] = useState(false);
    const { user,http } = AuthUser();
    const {selectedFactoryId} = useUserContext();

    const fetchInactiveMachines = async () => {

        setLoading(true);
        const { data } = selectedFactoryId &&  await http.get(`/inactive-machine-list?factory_id=${selectedFactoryId}`);
        setMachines(data);
        setLoading(false);
    }

    useEffect(() => {
        fetchInactiveMachines();
        const interval = setInterval(() => {
            fetchInactiveMachines();
        }, intervalTime);

        return () => clearInterval(interval);
    }, [selectedFactoryId])


    //no need to use loader while using setInterval
    return (
        <Wrapper>
            <InactiveMachineTable machines={machines}/>
        </Wrapper>
    );
}

export default InactiveMachine;



const Wrapper = styled.section`
.b-table {
  width: 100%;
  border-collapse: collapse;
  border: 5px solid var(--clr-primary-1);
  border-radius:8px !important;
  height:300px;
  overflow-y:auto;

  thead th {
    cursor: pointer;
    position: sticky;
    top: 0;
    background-color:#F1F5F8;
    white-space:nowrap ;
  }
}

`