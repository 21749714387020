import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "../components/Card";
import { Hero } from "../components/Hero";
import InactiveMachine from "../components/InactiveMachine";
import { SortPerformance } from "../components/SortPerformance";
import TodayMachine from "../components/TodayMachine";
import HighChartComponent from "../components/HighChartComponent";
import Motion from "../components/Motion";
import PerformanceIndicator from "../components/PerformanceIndicator";
import { useUserContext } from "../context/user_context";
import { useNavigate } from "react-router-dom";
import {intervalTime} from '../utils/constants';
import AuthUser from "../components/AuthUser";

const Overview = () => {
  const { user,http } = AuthUser();
  const [summary,setSummary] = useState([]);
  const [days, setDays] = useState(15);
  const {selectedFactoryId} = useUserContext();

  const updateDays = (days) => {
    setDays(parseInt(days));
  }

  const navigate = useNavigate();
  useEffect(()=>{
    if(!user){
      navigate('/login');
    }
  },[])

    //get total machine summary
    const fetchTotalMachineSummary = async () => {
      try {
          const { data } =  user  && selectedFactoryId &&  await http.get(`/factory-machine-summary?factory_id=${selectedFactoryId}`);
          setSummary(data);

      } catch (error) {
          
      }
  }

  useEffect(() => {
      fetchTotalMachineSummary();
      const interval = setInterval(() => {
          fetchTotalMachineSummary();
      }, intervalTime);

      return () => clearInterval(interval);
  }, [selectedFactoryId]);


    return (
      <Motion>
        <Wrapper className="page-100">
          <div className="section">
            <div className="section-center">
              <Hero />

              <div className="row mb-4">
                <div className="col-md-3">
                  <Card title="total machine" amount={summary.total} />
                </div>
                <div className="col-md-3">
                  <Card title="Active" color="#07C17E" amount={summary.active} />
                </div>
                <div className="col-md-3">
                  <Card title="idle" color="#FEC400" amount={summary.idle} />
                </div>
                <div className="col-md-3">
                  <Card
                    title="off-line"
                    color="#E0125A"
                    amount={summary.offline}
                  />
                </div>
              </div>

              {/* Inactive machine list */}

              <div className="row mt-4">
                <div className="col-md-6 mb-4">
                  <div className="title">
                    <h4>Inactive Machines</h4>
                    <InactiveMachine />
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="title">
                    <h4>Today</h4>
                    <TodayMachine />
                  </div>
                </div>
              </div>
              {/* performance history */}

              <h4>Performance History</h4>
              <PerformanceIndicator />
              <HighChartComponent days={days} />
              <SortPerformance days={days} updateDays={updateDays} />
            </div>
          </div>
        </Wrapper>
      </Motion>
    );
};

const Wrapper = styled.article``;

export default Overview;
