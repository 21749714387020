
import styled from "styled-components";

export function SortPerformance({days,updateDays}) {

    const values = [7, 15, 30];

  return (
    <Wrapper>
      <div className="d-flex justify-content-around">
      {
          values.map((v) => {
            return  <label key={v}> 
            
            <input className="form-check-input" 
              type='radio'
              name='days'
              value={v}
              checked={parseInt(days) === v}
              onChange={e =>updateDays(e.target.value)}
            />
           
            <span className="mx-1">{`Last ${v} days`}</span>
            </label>
          
            
          })
        }
      </div>

    </Wrapper>
  );


}

const Wrapper = styled.section`
label{
  cursor: pointer;
}
`

