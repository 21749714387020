export const SIDEBAR_OPEN = 'SIDEBAR_OPEN'
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE'
export const SORT_DAYS = 'SORT_DAYS'
export const UPDATE_SORT_DAYS = 'UPDATE_SORT_DAYS'
export const GET_CHART_DATA = 'GET_CHART_DATA'
export const SORT_CHART_DATA = 'SORT_CHART_DATA'
export const SET_GRAPH_VIEW = 'SET_GRAPH_VIEW'
export const SET_TABLE_VIEW = 'SET_TABLE_VIEW'

export const SET_MACHINE_SUMMARY = 'SET_MACHINE_SUMMARY'
export const SET_FLOORS = 'SET_FLOORS'

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const CHANGE_SELECTED_FACTORY_ID = 'CHANGE_SELECTED_FACTORY_ID'
export const CHANGE_SELECTED_FLOOR_ID = 'CHANGE_SELECTED_FLOOR_ID'