import React,{useState,useMemo} from 'react';

const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);

    const sortedItems = useMemo(() => {
        let sortableItems = [...items];

        if (sortConfig !== null) {


            if (sortConfig.key === 'last_activity') {
                sortableItems.sort((a, b) => {

                    if (a[sortConfig.key] < b[sortConfig.key]) {

                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                });
            }

            else if (sortConfig.key === 'id') {
                sortableItems.sort((a, b) => {

                    if (a.machineId < b.machineId) {

                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a.machineId > b.machineId) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                });
            }

            else {
                sortableItems.sort((a, b) => {

                    if (a[sortConfig.key] < b[sortConfig.key]) {

                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                });

            }
        }

        return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
}

export default useSortableData;
