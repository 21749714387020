
import React from 'react';
import styled from 'styled-components'
import Motion from './Motion';
const TableView = ({ report,handleExport }) => {

  return <Motion>
  <Wrapper>
    <div className='d-flex justify-content-between'>
      <h4>Data Table</h4>
      <button className='btn mb-2' onClick={handleExport}>export</button>
    </div>
    <div className="b-table">

    <table className="table table-striped text-center">
      <thead className='shadow'>
        <tr>
          <th scope="col">Time</th>
          <th scope="col">Avg RPM</th>
          <th scope="col">Loss Time</th>
          <th scope="col">Interruption</th>
        </tr>
      </thead>
      <tbody>
        {
          report.map((r, idx) => {
          
            return (
              <tr key={idx}>
                <td>{r[0]}</td>
                <td>{r[1]["avg-rpm"]}</td>
                <td>{r[1].lossTime}</td>
                <td>{r[1].interrupt}</td>
              </tr>
            )
          })
        }

      </tbody>
    </table>
    </div>
  </Wrapper>

  </Motion> 
}


const Wrapper = styled.section`
.b-table {
  width: 100%;
  border-collapse: collapse;
  border: 5px solid var(--clr-primary-1);
  border-radius:8px !important;
  height:300px;
  overflow-y:auto;

  thead th {
    position: sticky;
    top: 0;
    background-color:#F1F5F8;
  }
}

`

export default TableView;
