
import React from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis,Tooltip } from 'recharts';
import styled from 'styled-components';
import moment from 'moment';

const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
    const {Time,rpm} = payload[0].payload
		return (
  
			<div className="custom-tooltip shadow">
				<strong>{`${rpm} RPM`}</strong><br />
        <span>at</span>
				<p>{` ${moment(Time,'HHmm').format('hh:mm A')}`}</p>
			</div>
     
		);
	}

	return null;
};

const SingleMachineProductionGraph = ({ report }) => {

  const calculateProductionData = () => {

    const data_array = [];

    report.map((r) => {
     
      data_array.push({
        "Time": r[0].slice(10, 16),
        "rpm": r[1]["avg-rpm"]
      });
    });
    return data_array;
  }

  const production_graph_data = calculateProductionData();
 
  return <Wrapper className='mb-4'>
    <h4>productivity</h4>
   
      <div className='chart'>
        <ResponsiveContainer>
          <LineChart
            width={500}
            height={300}
            data={production_graph_data}
            margin={{
              right:20
            }}

          >
            <CartesianGrid />
            <XAxis dataKey="Time" interval={production_graph_data.length > 30 ? 4 : 0}  />
            <YAxis dataKey="rpm" />
            <Tooltip content={<CustomTooltip/>}/>
            <Line type="monotone"
              dataKey="rpm"
              stroke="#00ffff"
              strokeWidth={2}
              dot={false}
            />
            {/* <Line type="monotone" dataKey="Loss" stroke="#82ca9d" /> */}
          </LineChart>
        </ResponsiveContainer>
      </div>
 
  </Wrapper>
}

const Wrapper = styled.section`
  .chart{
    width:100%;
    height:300px;
    background-color: var(--clr-primary-1);
    border-radius: 8px;
  }
  .custom-tooltip{
    background-color:var(--clr-grey-10);
    padding:5px ;
    text-align:center;
    color:var(--clr-primary-1) ;
  }

`

export default SingleMachineProductionGraph;
