import React, { useRef, useState, useEffect } from 'react'
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import networkgraph from "highcharts/modules/networkgraph";
import Loader from './Loader';
import { useUserContext } from '../context/user_context';
import AuthUser from './AuthUser';

networkgraph(Highcharts);


const HighChartComponent = ({ days }) => {
  const { user,http } = AuthUser();
  const {selectedFactoryId} = useUserContext();
  const chartComponent = useRef(null); // reference to chart obj
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'column',
      backgroundColor: '#04122C',

    },
    credits:{
      enabled:false
    },
    title: {
      text: ''
    },
    tooltip: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    xAxis: {
      categories: [],
   
    },
    yAxis: {
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      allowDecimals: false,
      allowPointSelect: false,
      min: 0,
      max: 100,
      title: {
        text: ''
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: 'normal',
          textOutline: false ,
          color: ( // theme
            Highcharts.defaultOptions.title.style &&
            Highcharts.defaultOptions.title.style.color
          ) || 'red'
        }
      }
    },


    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          format: '{point.y}%'
        },
        shadow: false,
        center: ['50%', '50%'],
        borderWidth: 0 
      },
  
    },


    series: [

    ],

  });

  const [chartConfig, setChartConfig] = useState(chartOptions);
  const [loading, setLoading] = useState(false);


  const fetchChartData = async () => {

    try {
      setLoading(true);
      const { data } = selectedFactoryId &&  await http.get(`/daily-performance-summary?duration=${days}&factory_id=${selectedFactoryId}`);
     
      setLoading(false);
      setChartConfig({
        ...chartConfig,
        xAxis: {
          categories: data.map(d => [d.date])
        },
        gridLineWidth: 0,
        series: [
          {
            data: data.map(d => ({ y: d.loss, color: '#E0125A' }))
          },
          {
            data: data.map(d => ({ y: d.production, color: '#C8D9D3' }))
          },
        ]
  
      });
    } catch (error) {
      
    }
  }


  useEffect(() => {
    fetchChartData();
  }, [days, selectedFactoryId]);


  if (loading) {
    return <Loader height={350} />
  }


  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartConfig}
      containerProps={{ style: { height: 350 } }}
      allowChartUpdate={true}
      ref={chartComponent}
    />
  );
}

export default HighChartComponent;