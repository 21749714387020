import Navbar from "./components/Navbar";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Footer from "./components/Footer";
import Error from "./pages/Error";
import Sidebar from "./components/Sidebar";
import { AppProvider } from "./context/app_context";
import Overview from "./pages/OverviewPage";
import LivePage from "./pages/LivePage";
import ReportPage from "./pages/ReportPage";
import LoginPage from "./pages/LoginPage";
import { UserProvider, useUserContext } from "./context/user_context";
import { useEffect, useState } from "react";
import ProtectedRoute from "./components/ProtectedRoute";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {

  return (
    <BrowserRouter>
      <UserProvider>
        <AppProvider>
          <Navbar />
          <Sidebar />

          <Routes>
            <Route
              path="/login"
              element={<LoginPage/>}
            />

            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/" element={<Overview />} />
              <Route path="live" element={<LivePage />} />
              <Route path="reports" element={<ReportPage />} />
            </Route>
            <Route path="*" element={<Error />} />
          </Routes>
          <ToastContainer />
          <Footer />
        </AppProvider>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
