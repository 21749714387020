
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { intervalTime, URL } from "../utils/constants.js";
import Floor from "./Floor.js";
import UniqueFloor from "./UniqueFloor.js";
import axios from 'axios';
import AuthUser from './AuthUser';
import { useUserContext } from '../context/user_context';

const Floors = () => {

  const { user,http } = AuthUser();
  const {selectedFactoryId} = useUserContext();
  const [floors, setFloors] = useState([]);
  const [floorData, setFloorData] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState();
  const [selectedFloorId, setSelectedFloorId] = useState(user.default_floor);

 
  //all unique floors
  const fetchTotalFloors = async () => {

    const { data } = selectedFloorId && await http.get(`/factory-floors?factory_id=${user.default_factory}&floor_id=${selectedFloorId}`);
    data && setFloors(Object.values(data));
    data && selectedFloorId && setSelectedFloor(data[selectedFloorId]);
  }
  useEffect(() => {
    fetchTotalFloors();
  }, [selectedFactoryId,selectedFloorId])


  const handleFloorIdChange = (e) => {
    
    setSelectedFloorId(e.target.id)
  }


  //for dummy purpose
  const [firstFloorData, setFirstFloorData] = useState([]);

  //fetch first floor dummy data
  const fetchFirstFloorData = async () => {
    const { data } = await axios.get("first_floor.json");
    data && setFirstFloorData(data);
  };

  useEffect(() => {
    fetchFirstFloorData();
  }, []);


  //fetch floor wise data
  const fetchFloorWiseAllMachineData = async () => {

    try {
      const { data } = selectedFloorId && await http.get(`/floor-wise-machine-list?factory_id=${selectedFactoryId}&floor_id=${selectedFloorId}`);
      // data && setFloorData(Object.values(data));

      //temporary***
      if (selectedFloor === '1st Floor') {
        setFloorData(firstFloorData && firstFloorData)
      }
      else {
        setFloorData(data && data[selectedFloor]);
      }

    } catch (error) {
      
    }
  };

  useEffect(() => {

    fetchFloorWiseAllMachineData();

    const interval = setInterval(() => {
      fetchFloorWiseAllMachineData();
    }, intervalTime);
    return () => clearInterval(interval);
  }, [selectedFloor,selectedFactoryId]);


  return (
    <Wrapper>
      <UniqueFloor floors={floors}
        selectedFloor={selectedFloor}
        handleChange={handleFloorIdChange} />

      <Floor floor_data={floorData} />
    </Wrapper>
  );
};

const Wrapper = styled.section``;
export default Floors;
