import React from 'react';
import styled from 'styled-components';

const UniqueFloor = ({selectedFloor,floors,handleChange}) => {
  
  return <Wrapper>
    <div className="section">
      <div className="section-center">
        <div className="btn-container">
          {
            floors.map((f, i) => {
              return (
                <button id={i + 1} key={i} className={`filter-btn ${floors[`${i}`] === selectedFloor ? 'active' : ''} `}
                  onClick={handleChange}>{f}</button>
              )
            })
          }
          {/* <button className={`filter-btn ${floors["2"] === selectedFloor ? 'active' : ''}`} onClick={() => filterItems(floors["2"])}>{floors["2"]}</button> */}
        </div>
      </div>
    </div>
  </Wrapper>
};

const Wrapper = styled.section`
  background-color: #102B5E;
  .btn-container {
  display: flex;
  justify-content: center;
}
.filter-btn {
  background: transparent;
  border-color: transparent;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0 0.5rem;
  letter-spacing: 1px;
  padding: 0.375rem 0.75rem;
  color: var(--clr-primary-10);
  cursor: pointer;
  transition: var(--transition);
  border-radius: var(--radius);


}
.filter-btn:hover,.active {
  color: #01E7AC;
  border-bottom:2px solid #01E7AC;
}

button:disabled, button:disabled:hover{
  color: #000;
  border-bottom: 0;
  cursor:not-allowed;
}


`

export default UniqueFloor;
