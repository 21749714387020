import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUserContext } from "../context/user_context";
import AuthUser from "./AuthUser";

const ProtectedRoute = () => {
  const { getToken,user } = AuthUser();
  const { userInfo } = useUserContext();

  return user ? <Outlet /> : <Navigate to="/login" />;
  // return  isAuth ? <Outlet /> : <Navigate to='/login' />
};

export default ProtectedRoute;
