import React from 'react';
import GraphView from './GraphView';
import TableView from './TableView';

const SortView = ({report,handleExport,graph_view}) => {
    
    if(graph_view){
        return <GraphView report={report}>Sort view</GraphView>
    }

    return <TableView report={report} handleExport={handleExport}>Sort view</TableView>


};

export default SortView;
