
import React from 'react'
import styled from 'styled-components'
const Footer = () => {
  return <Wrapper><h6>&copy; {new Date().getFullYear()} FactoryNext | All rights reserved Arthor Ltd.</h6></Wrapper>
}

const Wrapper = styled.footer`
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--clr-primary-1);
  text-align: center;
  span {
    color: var(--clr-primary-5);
  }
  h6 {
    color: var(--clr-white);
    margin: 0.1rem;
    font-weight: 400;
    text-transform: none;
    line-height: 1.25;
    font-size:0.8rem;
  }
  @media (min-width: 776px) {
    flex-direction: row;
  }
`

export default Footer