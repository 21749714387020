import React from 'react';

export const Hero = ({ title }) => {
    let date = new Date();
    const today = date.toLocaleString('en-US', {
        //weekday: 'short', // long, short, narrow
        day: 'numeric', // numeric, 2-digit
        year: 'numeric', // numeric, 2-digit
        month: 'short', // numeric, 2-digit, long, short, narrow
        hour: 'numeric', // numeric, 2-digit
        minute: 'numeric', // numeric, 2-digit
       // second: 'numeric', // numeric, 2-digit
    })
     // Output: Tue, July 21, 2020, 10:01:14 AM
    return <div className="description">
           {today}
       </div>
    
};
