import React from "react";
import styled from "styled-components";

const PerformanceIndicator = () => {
  return (
    <Wrapper>
      <div className="d-flex">
        <div className="box-wrapper">
          <div className="box production"></div>&nbsp; Total Productive Hour (%)
        </div>
        <div className="box-wrapper">
          <div className="box loss"></div>&nbsp; Total Loss Hour (%)
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .box {
    float: left;
    height: 20px;
    width: 20px;
    border: 1px solid var(--clr-primary-1);
    clear: both;
  }

  .box-wrapper {
    margin-bottom: 10px;
    color: var(--clr-primary-1);
    padding: 5px 0px;
    border-radius: var(--radius);
    &:first-child {
      margin-right: 15px;
    }
  }

  .production {
    background-color: #c8d9d3;
  }
  .loss {
    background-color: #e0125a;
  }

  @media screen and (max-width: 500px) {
    .box {
      float: left;
      height: 15px;
      width: 15px;
      border: none;
      clear: both;
    }
    .box-wrapper {
      white-space: nowrap;
      font-size: 10px;
      padding: 5px 3px;
    }
  }
`;

export default PerformanceIndicator;
