import React from 'react'
import { motion } from 'framer-motion';

const Motion = ({ children }) => {
    const _variant = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                delay: 0.4, duration: 0.4,
            }
        }
    }
    return (
        <motion.div
            variants={_variant}
            initial='hidden'
            animate='visible'
        >{children}</motion.div>
    )
}

export default Motion