import {
    CHANGE_SELECTED_FACTORY_ID,

    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT
} from "../action"


export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true, }
        case USER_LOGIN_SUCCESS:
            return {loading: false, user: action.payload}
        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload }
        case CHANGE_SELECTED_FACTORY_ID:
            return { ...state, selectedFactoryId: action.payload, }
        case USER_LOGOUT:
            return {}

        default:
            return state
    }
}

