import React from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip} from 'recharts';
import styled from 'styled-components';
import PerformanceIndicator from './PerformanceIndicator';
import moment from 'moment';

const SingleMachineBarChart = ({ report }) => {


  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const {time,runtime,losstime} = payload[0].payload
      
      return (
    
        <div className="custom-tooltip shadow">
          <p className='fw-bold'>{`Production: ${runtime} %`}</p>
          <p className='fw-bold'>{`Loss : ${losstime} %`}</p>
          <span>at</span>
          <p>{` ${moment(time,'HHmm').format('hh:mm A')}`}</p>
         <p> <small> N.B : Each bar represents in 15 min</small></p>
        </div>
       
      );
    }
  
    return null;
  };
  
  const calculatePercentage = (time) => {

    let a =  time.split(':');
    // let b = ( "14:17:45").split(':')
    let TimeInSeconds = (+a[0]) * 60  + (+a[1]) 
    // let lossTimeInSeconds = (+b[0]) * 60 *60 + (+b[1]) * 60 + (+b[2])

    const calculatedTime =  Math.floor(((100 * TimeInSeconds) / (15 * 60)));
   
    return calculatedTime
  }



  const calculateProductionData = () => {

    const data_array = [];

    report.map((r) => {
      data_array.push({
        "time": r[0].slice(10, 16),
        "runtime": calculatePercentage(r[1].runtime === null ? "0:0" : r[1].runtime),
        "losstime": calculatePercentage(r[1].lossTime === null ? "0:0" : r[1].lossTime),
      });
    });
  
    // console.log(data_array)
    return data_array;
  }


  const chart_data = calculateProductionData()

  return <>
    <h4>active / loss time</h4>
    <PerformanceIndicator/>
    <Wrapper>
      <div style={{ width: '100%', height: 250 }}>
        <ResponsiveContainer>
          <BarChart
            width={300}
            height={500}
            data={chart_data}
            margin={{
              right:20
            }}

          >

            <XAxis dataKey="time" interval={chart_data.length > 30 ? 4 : 0} />
            <YAxis />
            <Tooltip content={<CustomTooltip/>}/>
            <Bar dataKey="runtime" stackId="a" fill="#C8D9D3" />
            <Bar dataKey="losstime" stackId="a" fill="#E0125A" />
            {/* <Bar
                            dataKey="RespondentPercentage"
                            label={<CustomizeLabel />}
                            barSize={100}
                            stackId="a"
                        /> */}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Wrapper>
  </>
}

const Wrapper = styled.section`
    background-color:var(--clr-primary-1);
    border-radius:8px;
    color: var(--clr-primary-10);
    text-align:center;
    margin:auto 0;
    padding:40px 0;
    
    p{
        margin:5px 0 0 0;
    }
    margin-bottom:10px;

    .custom-tooltip{
      background-color:var(--clr-grey-10);
      padding:5px ;
      text-align:center;
      color:var(--clr-primary-1) ;
      small{
        color:var(--clr-primary-1) ;
      }
    }

    
`
export default SingleMachineBarChart;
