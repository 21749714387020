import React from "react";
import styled from "styled-components";
import Motion from "./Motion";
import moment from 'moment'

const Floor = ({ floor_data = [] }) => {

  const checkMachineType = (type) => {
    const arr = [];
    arr.push(floor_data.filter((f) => f.type === type));
    return arr;
  };

  const checkState = (type) => {
    if (type === "ON") {
      return "green";
    }
    if (type === "OFFLINE") {
      return "red";
    }
    if (type === "IDLE") {
      return "yellow";
    }

    if (type === "UPCOMING") {
      return "#808080";
    }
  };

  return (
    <Wrapper>
      <Motion>
        <div className="section">
          <div className="section-center">
            <div className="row d-flex justify-content-between">
              {floor_data && floor_data.find((f) => f.type === "12 GG" || f.type === "12GG") && (
              <div className="col-md-5 text-center mb-5">
                {floor_data &&
                  floor_data.find(
                    (f) => f.type === "12 GG" || f.type === "12GG"
                  ) && <h4>12 gg machines</h4>}

                {(() => {
                  const values = [];
                  for (
                    let i = 0;
                    i < (checkMachineType("12 GG")[0].length / 5);
                    i++
                  ) {
                    values.push(
                      <div className="row indicator-box mb-4" key={i}>
                        {floor_data &&
                          floor_data
                            .filter(
                              (d) => d.type === "12 GG" || d.type === "12GG"
                            )
                            .slice(5 * i, 5 * (i + 1))
                            .map((data) => {

                              return (
                                <div
                                  className="box-wrapper"
                                  key={data.machineId}
                                >
                                  <div
                                    className="clr-btn"
                                    style={{
                                      backgroundColor: checkState(data.state),
                                    }}
                                  ></div>
                                  <div className="value">{data.machineId}</div>
                                </div>
                              );
                            })}

                        <div className="operator" key={i}>

                          {
                            floor_data && floor_data.find(f => f.group_id === i + 1).shift.map((s) => {
                              return <p className="text-start d-inline" key={s.id}>
                                <strong>{s.name} : </strong>{s.worker}
                                <small>&nbsp; [{moment(s.schedule.slice(0,8),'HHmm').format('hh:mm A')} - {moment(s.schedule.slice(12,18),'HHmm').format('hh:mm A')}]</small>
                                  <br/>
                                </p>
                                
                            })
                          }

                        </div>
                      </div>

                    );

                  }

                  return values;
                })()}
              </div>
              )}

              {floor_data && floor_data.find((f) => f.type === "Multi GG") && (
                <div className="col-md-5 mb-5 text-center">
                  {floor_data &&
                    floor_data.find((f) => f.type === "Multi GG") && (
                      <h4>multi gg machines</h4>
                    )}
                  {(() => {
                    const values = [];

                    for (
                      let i = 0;
                      i < (checkMachineType("Multi GG")[0].length / 5);
                      i++
                    ) {
                      values.push(
                        <div className="row indicator-box mb-4" key={i}>
                          {floor_data &&
                            floor_data
                              .filter(
                                (d) => d.type === "Multi GG"
                              )
                              .slice(5 * i, 5 * (i + 1))
                              .map((data) => {

                                return (
                                  <div
                                    className="box-wrapper"
                                    key={data.machineId}
                                  >
                                    <div
                                      className="clr-btn"
                                      style={{
                                        backgroundColor: checkState(data.state),
                                      }}
                                    ></div>
                                    <div className="value">
                                      {data.machineId}
                                    </div>
                                  </div>
                                );
                              })}

                          <div className="operator" key={i}>

                            {
                              floor_data && floor_data.find(f => f.group_id === i + 1).shift.map((s) => {
                                return  <p className="text-start d-inline" key={s.id}>
                                  <strong>{s.name} : </strong>{s.worker}
                                  <small>&nbsp; [{moment(s.schedule.slice(0,8),'HHmm').format('hh:mm A')} - {moment(s.schedule.slice(12,18),'HHmm').format('hh:mm A')}]</small>
                                 
                                  <br/>
                                  </p> 
                              })
                            }

                          </div>
                        </div>
                      );
                    }

                    return values;
                  })()}
                </div>
              )}

              {floor_data && floor_data.find((f) => f.type === "Lisky - 32 x 24") && (
                  <div className="col-md-5 mb-5 text-center">
                    {floor_data &&
                        floor_data.find((f) => f.type === "Lisky - 32 x 24") && (
                            <h4>Lisky - 32 x 24 machines</h4>
                        )}
                    {(() => {
                      const values = [];
                      for (
                          let i = 0;
                          i < (checkMachineType("Lisky - 32 x 24")[0].length / 5);
                          i++
                      ) {
                        values.push(
                            <div className="row indicator-box mb-4" key={i}>
                              {floor_data &&
                                  floor_data
                                      .filter(
                                          (d) => d.type === "Fukuhara 32x28"
                                      )
                                      .slice(5 * i, 5 * (i + 1))
                                      .map((data) => {

                                        return (
                                            <div
                                                className="box-wrapper"
                                                key={data.machineId}
                                            >
                                              <div
                                                  className="clr-btn"
                                                  style={{
                                                    backgroundColor: checkState(data.state),
                                                  }}
                                              ></div>
                                              <div className="value">
                                                {data.machineId}
                                              </div>
                                            </div>
                                        );
                                      })}

                              <div className="operator" key={i}>

                                {
                                    floor_data && floor_data.find(f => f.group_id === 14).shift.map((s) => {
                                      let splitted_schedule = s.schedule.split("-");
                                      return  <p className="text-start d-inline" key={s.id}>
                                        <strong>{s.name} : </strong>{s.worker}
                                        <small>&nbsp; [{moment(splitted_schedule[0].trim(),'HHmm').format('hh:mm A')} - {moment(splitted_schedule[1].trim(),'HHmm').format('hh:mm A')}]</small>

                                        <br/>
                                      </p>
                                    })
                                }

                              </div>
                            </div>
                        );
                      }

                      return values;
                    })()}
                  </div>
              )}

              {floor_data && floor_data.find((f) => f.type === "Fukuhara 32x28") && (
                  <div className="col-md-5 mb-5 text-center">
                    {floor_data &&
                        floor_data.find((f) => f.type === "Fukuhara 32x28") && (
                            <h4>Fukuhara 32x28 machines</h4>
                        )}
                    {(() => {
                      const values = [];
                      for (
                          let i = 0;
                          i < (checkMachineType("Fukuhara 32x28")[0].length / 5);
                          i++
                      ) {
                        values.push(
                            <div className="row indicator-box mb-4" key={i}>
                              {floor_data &&
                                  floor_data
                                      .filter(
                                          (d) => d.type === "Fukuhara 32x28"
                                      )
                                      .slice(5 * i, 5 * (i + 1))
                                      .map((data) => {

                                        return (
                                            <div
                                                className="box-wrapper"
                                                key={data.machineId}
                                            >
                                              <div
                                                  className="clr-btn"
                                                  style={{
                                                    backgroundColor: checkState(data.state),
                                                  }}
                                              ></div>
                                              <div className="value">
                                                {data.machineId}
                                              </div>
                                            </div>
                                        );
                                      })}

                              <div className="operator" key={i}>

                                {
                                    floor_data && floor_data.find(f => f.group_id === 15).shift.map((s) => {
                                      let splitted_schedule = s.schedule.split("-");
                                      return  <p className="text-start d-inline" key={s.id}>
                                        <strong>{s.name} : </strong>{s.worker}
                                        <small>&nbsp; [{moment(splitted_schedule[0].trim(),'HHmm').format('hh:mm A')} - {moment(splitted_schedule[1].trim(),'HHmm').format('hh:mm A')}]</small>

                                        <br/>
                                      </p>
                                    })
                                }

                              </div>
                            </div>
                        );
                      }

                      return values;
                    })()}
                  </div>
              )}


            </div>
          </div>
        </div>
      </Motion>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background-color: var(--clr-primary-1);
  min-height: 65vh;
  color: var(--clr-primary-10);

  .indicator-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 3px 1px 3px #888888;
  }
  .box-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 90px;
    height: 50px;
    padding: 10px;
    text-align: center;
    border: 5px solid var(--clr-primary-1);
    background: linear-gradient(
        0deg,
        rgba(4, 18, 44, 0.77),
        rgba(4, 18, 44, 0.77)
      ),
      #ffffff !important ;
    cursor: pointer;
  }

  .operator {
    display: block;
    text-align: center;
    color: var(--clr-primary-10);
    background-color: #102b5e;
    max-width: 455px;
    /* height: 45px; */
    padding: 5px 2px;
    align-items: center;
    margin: auto 0;
    .bar {
      color: var(--clr-primary-2);
      font-weight: 900;
    }

    p {
      font-size: 11px;
      color: var(--clr-primary-9);
      text-transform: capitalize;

      strong {
        text-transform: uppercase;
        font-weight: 600;
        color: var(--clr-primary-8);
      }
    }
  }

  @media screen and (min-width: 768px) and(max-width:1199px) {
    .box-wrapper {
      /* width: 80px; */
    }
  }
`;

export default Floor;
