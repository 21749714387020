import axios from 'axios';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import { URL_FOR_PROTECTED_APIs } from "../utils/constants.js";
import {axios_export_table_instance} from "../utils/axios";


export default function AuthUser(){
    const navigate = useNavigate();

    const getToken = () =>{
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }

    const getUser = () =>{
        const userString = sessionStorage.getItem('user');
        return JSON.parse(userString);
    }


    const [token,setToken] = useState(getToken());
    const [user,setUser] = useState(getUser());
    

    const saveToken = (user,token) =>{
        sessionStorage.setItem('token',JSON.stringify(token));
        sessionStorage.setItem('user',JSON.stringify(user));

        setToken(token);
        setUser(user);
        navigate('/');
    }

    const logout = () => {
        sessionStorage.clear();
        navigate('/login');
    }

    const http = axios.create({
        baseURL: URL_FOR_PROTECTED_APIs,
        headers:{
            "Content-type" : "application/json",
            "Authorization" : `Bearer ${token}`
        }
    });

    axios_export_table_instance.defaults.headers.common['Authorization'] = `Bearer ${token}`


    return {
        setToken:saveToken,
        token,
        user,
        getToken,
        http,
        logout
    }
}