import React from 'react'
import logo from '../assets/logo.png'
import { Link, useLocation } from 'react-router-dom'
import { FaTimes } from 'react-icons/fa'

import styled from 'styled-components'
import NotificationButton from './NotificationButton'
import { links } from '../utils/constants'
import { useAppContext } from '../context/app_context'


const Sidebar = () => {
  const location = useLocation();
    const {closeSidebar,isSidebarOpen} = useAppContext();
    return <SidebarContainer>
        <aside className={`${isSidebarOpen ? 'sidebar show-sidebar' : 'sidebar'}`}>
            <div className="sidebar-header">
                <img src={logo} alt="Company Next" className='logo' />
                <button className="close-btn" type='button' onClick={closeSidebar}><FaTimes /></button>
            </div>
            <ul className="links">
                {
                    links.map((link) => {
                        return <li key={link.id}><Link className={location.pathname === link.url ? 'active' : ''}  onClick={closeSidebar} to={link.url}>{link.text}</Link></li>
                    })
                }
                {/* <li>
                    <Link  onClick={closeSidebar} to='/checkout'>checkout</Link>
                </li> */}
            </ul>
            <NotificationButton />
        </aside>
    </SidebarContainer>
}

const SidebarContainer = styled.div`
 font-family: var(--ff-primary);
  text-align: center;
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
  }
  .close-btn {
    font-size: 2rem;
    background: transparent;
    border-color: transparent;
    color: var(--clr-primary-5);
    transition: var(--transition);
    cursor: pointer;
    color: var(--clr-red-dark);
    margin-top: 0.2rem;
  }
  .close-btn:hover {
    color: var(--clr-red-light);
  }
  .logo {
    justify-self: center;
    height: 45px;
    width:175px;
  }
  .links {
    margin-bottom: 2rem;
  }
  .links a {
    display: block;
    text-align: left;
    font-size: 1rem;
    text-transform: capitalize;
    padding: 1rem 1.5rem;
    color: var(--clr-grey-3);
    transition: var(--transition);
    letter-spacing: var(--spacing);
  }
  .links a:hover {
    padding: 1rem 1.5rem;
    padding-left: 2rem;
    background: var(--clr-grey-10);
    color: var(--clr-grey-2);
  }
  a.active{
    color: #17A2B8;
    font-weight:600 ;
  }
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--clr-white);
    transition: var(--transition);
    transform: translate(-100%);
    z-index: -1;
  }
  .show-sidebar {
    transform: translate(0);
    z-index: 999;
  }
  .notification-btn-wrapper {
    margin: 2rem auto;
  }
  @media screen and (min-width: 992px) {
    .sidebar {
      display: none;
    }
  }
`

export default Sidebar