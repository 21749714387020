import useSortableData from "../hooks/useSortableData";

const InactiveMachineTable = ({machines}) => {

    const { items, requestSort, sortConfig } = useSortableData(machines);
    const getClassNamesFor = (floor) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === floor ? sortConfig.direction : undefined;
    };
    return (
        <div className='b-table'>
            <table className='table table-striped'>
                <thead>
                    <tr>
                        <th
                            onClick={() => requestSort('id')}
                            className={getClassNamesFor('id')}
                        >
                            Id <i className="fas fa-angle-down"></i>

                        </th>
                        <th onClick={() => requestSort('floor')}
                            className={getClassNamesFor('floor')}>
                            Floor <i className="fas fa-angle-down"></i>

                        </th>
                        <th onClick={() => requestSort('type')}
                            className={getClassNamesFor('type')}>
                            Type <i className="fas fa-angle-down"></i>

                        </th>
                        <th onClick={() => requestSort('last_activity')}
                            className={getClassNamesFor('last_activity')}>
                            Last Production <i className="fas fa-angle-down"></i>

                        </th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item) => (

                        <tr key={item.machineId}>
                            <td>{item.machineId}</td>
                            <td>{item.floor}</td>
                            <td className={item.type === '12 GG' || item.type === '12GG' ? 'text-primary' : 'text-info'}>{item.type}</td>
                            <td className='text-danger'>{item.last_activity ? item.last_activity : '0 hrs ago'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}


export default InactiveMachineTable;