import axios from 'axios';
import {URL_FOR_PROTECTED_APIs} from "./constants";
// import AuthUser from "../components/AuthUser";


/*const tokenString = sessionStorage.getItem('token');
const token = JSON.parse(tokenString);*/


// const { token } = AuthUser(); //throws error that you cannot use hook outside of a functional component


export const axios_export_table_instance = axios.create({
    baseURL: URL_FOR_PROTECTED_APIs,
    /*headers:{
        "Authorization" : `Bearer ${token}`
    }*/
});

axios_export_table_instance.interceptors.request.use( req => {
        return req;
    }, err => {
        return Promise.reject(err);
    }
);


axios_export_table_instance.interceptors.response.use( res => {
    return res;
}, err => {
    return Promise.reject(err);
});

  
