import React from 'react';
import Motion from './Motion';
import SingleMachineBarChart from './SingleMachineBarChart';
import SingleMachineProductionGraph from './SingleMachineProductionGraph';

const GraphView = ({ report }) => {
  return <Motion>
    <SingleMachineProductionGraph report={report} />
    <SingleMachineBarChart report={report} />
  </Motion>
};

export default GraphView;
