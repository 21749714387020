import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Floors from '../components/Floors';
import { Hero } from '../components/Hero';
import {intervalTime} from '../utils/constants';
import AuthUser from '../components/AuthUser';
import { useUserContext } from '../context/user_context';

const LivePage = () => {
    const [summary,setSummary] = useState([]);
    const {selectedFactoryId} = useUserContext();
    const { user,http } = AuthUser();
    //get total machine summary
    const fetchTotalMachineSummary = async () => {

        try {
            const { data } =  await http.get(`/factory-machine-summary?factory_id=${selectedFactoryId}`);
            setSummary(data);
  
        } catch (error) {
            
        }
    }
  
    useEffect(() => {
        fetchTotalMachineSummary();
        const interval = setInterval(() => {
            fetchTotalMachineSummary();
        }, intervalTime);
  
        return () => clearInterval(interval);
    }, [selectedFactoryId]);


    return <Wrapper className='page-100'>
    
        <div className="section">
            <div className="section-center">
                <Hero />
            </div>
        </div>

        <div className="machine-amount">
            <div className="section">
                <div className="section-center">
                    <h6>Total Machines : {summary.total}</h6>
                    <div className="d-flex justify-content-around">
                        <p className='d-md-flex'><span className="clr-btn" style={{ backgroundColor: 'green' }}></span>&nbsp; Active : {summary.active}</p>
                        <p className='d-md-flex'><span className="clr-btn" style={{ backgroundColor: 'yellow' }}></span>&nbsp; Idle : {summary.idle}</p>
                        <p className='d-md-flex'><span className="clr-btn" style={{ backgroundColor: 'red' }}></span>&nbsp; Off-line : {summary.offline}</p>
                        <p className='d-md-flex'><span className="clr-btn" style={{ backgroundColor: '#808080' }}></span>&nbsp; Upcoming : 40</p>
                    </div>
                </div>
            </div>
        </div>

     
        <Floors/>
    </Wrapper>
}

const Wrapper = styled.section`
    font-family: var(--ff-primary);
    .clr-btn{
        display: inline-block;
        width:1rem;
        height:1rem;
        border-radius:50%;
        margin-right:0.1rem;
        border:none;
        margin:auto 0;
  
    }

    .machine-amount{
        background-color:var(--clr-primary-1);
        color: var(--clr-primary-10);
        text-align:center;
        margin:auto 0;
        padding:5px;
        p{
            margin:5px 0 0 0;
        }
    }

`
export default LivePage;
