import React from 'react';
import styled from 'styled-components';

const Card = ({ title, amount, color, children }) => {

  return <Wrapper>
      {children}
      <p>{title}</p>
      <h2 style={{ color: color }}>{amount}</h2>
  </Wrapper>
}

const Wrapper = styled.section`
    font-family: var(--ff-primary);
    background-color:var(--clr-primary-1);
    border-radius:8px;
    color: var(--clr-primary-10);
    text-align:center;
    margin:auto 0;
    padding:3px;
    p{
        margin:5px 0;
    }
    margin-bottom:10px;
    
`

export default Card;
