import React from 'react';
import styled from 'styled-components';


const SelectMachineOrDate = ({deviceId, date, machines, handleOnDeviceChange, handleOnDateChange}) => {
    return <Wrapper>
        <form>
            <div className='row'>
                <div className="col-sm-6">
                    <label htmlFor="">select  machine</label>
                    <select name="" className='select-input' value={deviceId}
                            onChange={(e) => handleOnDeviceChange(e)}>
                               <option value="" disabled>Select</option>
                        {
                            machines.map(machine=> <option key={machine.machineId} value={machine.identifier}>{machine.machineId}</option>)
                        }
                    </select>
                </div>

                <div className="col-sm-6">
                    <label htmlFor=""> select  date</label>
                    <input type="date"  value={date}  
                    onChange={(e) => handleOnDateChange(e) } 
                    className='date' />
                </div>
            </div>
        </form>
    </Wrapper>
}

const Wrapper = styled.section`
    label{
        text-transform:Capitalize;
        margin-right:2.5rem;
        font-weight:600;
        white-space: nowrap;
    }
    .select-input{
        border: 1px solid var(--clr-primary-1);
        color:var(--clr-primary-10);
        background: #8C9AB4;
        border: 2px solid #04122C;
        border-radius: 5px;
        padding: 0.25rem 0.4rem;
        margin-bottom:10px;
        text-align:center;
        width:135px;

    }
    .date{
        border: 1px solid var(--clr-primary-1);
        color:var(--clr-primary-10);
        background: #8C9AB4;
        border: 2px solid #04122C;
        border-radius: 5px;
        padding: 0.25rem 0;
        margin-bottom:10px;
        text-align:center;
        width:165px;
    }
`

export default SelectMachineOrDate;
