import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { URL } from "../utils/constants";
import Loader from "./Loader";
import { useUserContext } from "../context/user_context";
import AuthUser from "./AuthUser";

const TodayMachine = () => {
  const [loading, setLoading] = useState(false);
  const {selectedFactoryId} = useUserContext();
  const [dailyMachineProduction, setDailyMachineProduction] = useState({});
  const { user,http } = AuthUser();
  const getDailyProductionSummary = async () => {

    try {
      setLoading(true);
      const { data } = selectedFactoryId &&  await http.get(`/daily-production-summary?factory_id=${selectedFactoryId}`);
      setDailyMachineProduction(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getDailyProductionSummary();
    }
    return () => {
      isSubscribed = false;
    };
  }, [selectedFactoryId]);

  const calculateTime = () => {
    const { loss_time, total_production_hrs } = dailyMachineProduction;

    let l = loss_time && loss_time.split(/[hms]+/);
    let t = total_production_hrs && total_production_hrs.split(/[hms]+/);

    let lossTimeInSeconds = l && +l[0] * 60 * 60 + +l[1] * 60 + +l[2];
    let totalTimeInSeconds = t && +t[0] * 60 * 60;

    return (lossTimeInSeconds * 100) / totalTimeInSeconds;
  };

  if (loading) {
    return <Loader height={300} />;
  }

  return (
    <Wrapper>
      <div className="title">
        <p>Expected Machine Hour</p>
        <h4 style={{ color: "#DCED9A" }}>
          {dailyMachineProduction.total_production_hrs} <br />{" "}
          <span>(20 machines X 22 hrs)</span>{" "}
        </h4>
        <p>Active Machine Hour</p>
        <h4 style={{ color: "#07C17E" }}>
          {dailyMachineProduction.total_active_machine_hrs}
        </h4>
        <p>Loss TIme</p>
        <h4 style={{ color: "#E0125A" }}>
          {dailyMachineProduction.loss_time}&nbsp; [{dailyMachineProduction.loss_percentage}%]
        </h4>
        <p>Hours Left</p>
        <h4 style={{ color: "#8A8E8E" }}>
          {dailyMachineProduction.hours_left}
        </h4>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background-color: var(--clr-primary-1);
  border-radius: 8px;
  color: var(--clr-primary-10);
  text-align: center;
  margin: auto 0;
  padding: 10px 0;

  span {
    font-size: 12px;
    text-transform: capitalize;
  }

  @media screen and (min-width: 768px) {
    height: 300px;
  }

  p {
    margin: 5px 0 0 0;
  }
`;
export default TodayMachine;
