import React from 'react';
import { Link,useLocation } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/logo.png';
import { FaBars,FaAlignRight } from 'react-icons/fa';
import { links } from '../utils/constants';
import NotificationButton from './NotificationButton';
import { useAppContext } from '../context/app_context';

const Navbar = () => {
  const location = useLocation();
  const { openSidebar,closeSidebar } = useAppContext();
  const user = JSON.parse(sessionStorage.getItem('user'));
  return (
    <Wrapper className='shadow section'>
      <div className="nav-center section-center">
        <div className="nav-header">
          <Link to='/' onClick={closeSidebar}><img src={logo} alt="Factory Next" /></Link>
          <button className="nav-toggle" type='button' onClick={openSidebar}><FaAlignRight /></button>
        </div>
        <ul className="nav-links my-auto ">
          {
           user && links.map((link) => {
              return <li key={link.id}><Link className={location.pathname === link.url ? 'active' : ''} to={link.url}>{link.text}</Link></li>
            })
          }
        </ul>
        <NotificationButton />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background-color:var(--clr-grey-9);
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
 
  .nav-center {
    width: 90vw;
    margin: 0 auto;
    max-width: var(--max-width);
  }

  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 165px;
      margin-left: -15px;
    }
  }
  
  .nav-toggle {
    
    
    /* margin-top:400px; */
    background: transparent;
    border: transparent;
    color: var(--clr-primary-5);
    cursor: pointer;
    svg {
      font-size: 2rem;
    }
  }
  .nav-links {
    display: none;
    font-weight:600;
   
  }
  .notification-btn-wrapper {
    display: none;
  }

  a.active{
    border-bottom: 2px solid #17A2B8;
  }
  @media (min-width: 992px) {
    .nav-toggle {
      display: none;
    }
    .nav-center {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
    }
    .nav-links {
      display: flex;
      justify-content: center;
      li {
        margin: 0 0.5rem;
      }
      a {
        color: var(--clr-grey-3);
        font-size: .9rem;
        text-transform: capitalize;
        letter-spacing: var(--spacing);
        padding: 0.5rem;
        &:hover {
          border-bottom: 2px solid #17A2B8;
        }
      }
    }
    .notification-btn-wrapper {
      display: grid;
    }
  }
`
export default Navbar
