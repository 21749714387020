
import React, { useState } from 'react'
import styled from 'styled-components'
import { useUserContext } from '../context/user_context'
import Message from '../components/Message'

const LoginPage = () => {

    const { login, loading } = useUserContext();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
  

    const submitHandler = (e) => {
        e.preventDefault();
        login(username, password);

    }



    return (
        <Wrapper>
            <div className='page-100'>
                <div className="section">
                    <div className="section-center">
                        <div className="row">
                            <div className="col-md-6 mx-auto">
                                {/* {error && <Message variant='danger'>{error}</Message>} */}
                                {/* {loading && <div className="d-flex justify-content-center align-items-center">
                                    <strong>Logging In...</strong>
                                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                                </div>} */}

                                <form onSubmit={submitHandler}>

                                    <div className="mb-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label"><h4>username</h4></label> <br />
                                        <input type="text"
                                            value={username}
                                            onChange={e => setUsername(e.target.value)}
                                            className="form-control"
                                            placeholder='Enter username ...' />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label"><h4>Password</h4></label> <br />

                                        <input type="password"
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                            className="form-control"
                                            placeholder='Enter password ...' />
                                    </div>
                                    <button type="submit" className="btn">{loading ? 'Loading...' : 'Submit'}</button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.section`

    form{
        margin-top: 50px;
        input{
            text-transform:initial ;
        }
      
    }


`

export default LoginPage
